import React from "react";
import PropTypes from "prop-types";

import Image from "../Global/Image";
import Link from "../Global/Link";
import SmartColour from "../../utils/ColourHandler";

const CardProduct = (props) => {
  const { data, colour } = props;

  //  return <p>{JSON.stringify(data)}</p>;

  let item = null;

  if (data?.link?.story?.content) {
    item = data?.link?.story?.content;
  } else if (data?.content) {
    item = JSON.parse(data.content);
  }

  const item_colour = SmartColour(colour);

  if (!item) return null;
  return (
    <div
      className={`d-flex flex-column brand-background-white brand-text-black text-center  brand-border-radius overflow-hidden h-100 brand-background-${item_colour.background} brand-text-${item_colour.text}`}
    >
      <div className="brand-background-grad">
        {data?.link?.story?.full_slug || data?.full_slug ? (
          <Link to={data.full_slug || data?.link?.story?.full_slug}>
            <Image
              src={item.product?.filename}
              sm="300x300"
              md="400x400"
              lg="460x460"
              alt={item.product?.alt}
              className="w-100"
            />
          </Link>
        ) : (
          <Image
            src={item.product?.filename}
            sm="300x300"
            md="400x400"
            lg="460x460"
            alt={item.product?.alt}
            className="w-100"
          />
        )}
      </div>

      <div className="d-flex flex-column h-100 p-4">
        {item.title && <h4 className="m-0 mb-4 brand-font">{item.title}</h4>}
        <div className="mt-auto">
          <Link
            to={data.full_slug || data?.link?.story?.full_slug}
            button
            colour={item_colour.text}
          >
            More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardProduct;

CardProduct.propTypes = {
  item: PropTypes.object,
  colour: PropTypes.object,
};

CardProduct.defaultProps = {
  item: null,
  colour: "white",
};
